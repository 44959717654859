import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"

const axios = require("axios");
const jQuery = require("jquery");

const ContactoPage = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [direction, setDirection] = useState("")
    const [subject] = useState("Usuario desea contactar con el administrador del sitio Fundación Pedro Pérez Garrido");
    const [sitio] = useState("Fundación Pedro Pérez Garrido");

    const send = async function (e) {
        e.preventDefault()

        jQuery("#btn-cont").css('pointer-events', 'none')
        jQuery("#btn-cont").html('Enviando...')

        if (!executeRecaptcha) {
            return
        }

        if (email.length === 0 || email.trim().length === 0) {
            return
        } else if (message.length === 0 || message.trim().length === 0) {
            return
        } else if (subject.length === 0 || subject.trim().length === 0) {
            return
        } else if (name.length === 0 || name.trim().length === 0) {
            return
        }
        const result = await executeRecaptcha("contacto")
        const headers = {
            "Content-Type": "application/json"
        }
        axios
            .post(
                "https://us-central1-cloudsites-190318.cloudfunctions.net/mails-fmp",
                {
                    name,
                    email,
                    message,
                    subject,
                    phone,
                    sitio,
                    token: result,
                    direction
                },
                {
                    headers: headers
                }
            )
            .then(res => {
                jQuery("#contact-form").slideUp("slow").hide();
                jQuery("#result").append(
                    '<hr class="mt-4"><div class="success text-center"><h4>¡Correo electrónico enviado con éxito!</h4><br><p>Gracias por usar nuestro formulario de contacto</p></div><hr>'
                )
            })
    }

    return (
        <Layout>
            <SEO title="Home"/>

            <div className="up bg-white spacer feature3 mt-5">
                <div className="container">
                    <div className="row m-0">
                        <div className="col-lg-7">
                            <div className="card-shadow">
                                <img src={`../../images/contact/contacto.jpg`} alt="wrapkit"
                                     className="img-responsive"/>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-box m-l-30">
                                <h1 className="title font-light m-t-10">Contáctanos</h1>
                                <div id="result"></div>
                                <form onSubmit={event => send(event)} method="post" id="contact-form">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                                <input name="name" required className="form-control"
                                                       placeholder="Nombre"
                                                       onChange={event => setName(event.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                                <input name="email" required className="form-control" type="email"
                                                       placeholder="Dirección de correo"
                                                       onChange={event => setEmail(event.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                                <input name="phone" className="form-control" type="tel"
                                                       placeholder="Teléfono"
                                                       onChange={event => setPhone(event.target.value)}/></div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                            <textarea name="mensaje" required className="form-control"
                                                      placeholder="Mensaje" defaultValue={""}
                                                      onChange={event => setMessage(event.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group m-t-10">
                                            <textarea name="direction" required className="form-control"
                                                      placeholder="Dirección" defaultValue={""}
                                                      onChange={event => setDirection(event.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <button type="submit" id="btn-cont"
                                                    className="btn btn-outline-info m-t-20 btn-arrow">
                                                <span> Enviar <i className="ti-arrow-right"></i></span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <iframe style={{width: 100 + '%', height: '200px'}} frameBorder="0" scrolling="no"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10701.950989782565!2d-88.30872652547708!3d18.496742737444606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5ba3699bbdd773%3A0x733c4fcae3fe6901!2zQXYgw4FsdmFybyBPYnJlZ8OzbiwgUXVpbnRhbmEgUm9vLCBNw6l4aWNv!5e0!3m2!1ses-419!2scu!4v1616011526541!5m2!1ses-419!2scu"
                                    title="Avenida Álvaro Obregón, Centro, Chetumal, Q.R."
                                    aria-label="Avenida Álvaro Obregón, Centro, Chetumal, Q.R.">

                            </iframe>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default ContactoPage
